<template>
  <div class="rate-carrier-success_container">
    <div class="rate-carrier-success_star-rating">
      <font-awesome-icon
        v-for="i in 5"
        :key="i"
        :icon="faStar"
        :style="{ color: i > parseFloat(score) ? '#C3C5C7' : '#FFD43B'}"
        class="rate-carrier-success_star"
      />
    </div>
    <p class="rate-carrier-success_title">
      {{ $t('tracking.confirmation.title') }}
    </p>
    <p class="rate-carrier-success_text">
      {{ $t('tracking.confirmation.body_text') }}
    </p>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { faStar } from '@fortawesome/free-solid-svg-icons'

const route = useRoute()
const { score } = route.query
</script>

<style lang="scss" scoped>
.rate-carrier-success_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $sc-white;
  width: fit-content;
  padding-inline: $sc-spacing-32;
  padding-block: $sc-spacing-16;
  border: 1px solid $sc-light-grey;
  border-radius: $sc-border-radius;
  box-shadow: 0 4px 3px 2px rgba(0, 0, 0, 0.2);
}

.rate-carrier-success_star-rating {
  margin-top: $sc-spacing-8;
  margin-bottom: $sc-spacing-32;
}

.rate-carrier-success_star {
  height: 2em !important;
}

.rate-carrier-success_title {
  font-weight: 600;
  color: $sc-black;
}
</style>

<template>
  <div class="rate-my-carrier-page">
    <router-view />
    <a
      href="https://www.sendcloud.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <UiLogo
        class="sendcloud-logo"
      />
    </a>
  </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import rateMyCarrierService from '../api/rate-my-carrier.js'
import UiLogo from '@sendcloud/ui-library/src/components/UiLogo/UiLogo.vue';

const router = useRouter()
const route = useRoute();
const queryParams = route.query;

onBeforeMount(async () => {
  const { token, score } = queryParams
  try {
      await rateMyCarrierService.getRating(token, score)
      router.push({ path: `/rate-carrier-experience/success`, query: {token: token, score: score }})
    } catch (e) {
      router.push('/rate-carrier-experience/error')
    }
})
</script>

<style lang="scss">
.rate-my-carrier-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #F4FAFF;
  padding: $sc-spacing-20;
}

.sendcloud-logo {
  width: 20rem;
  position: absolute;
  bottom: 5vh;
  left: 5vw;

  @include breakpoint-max-width(small) {
    left: 50%;
    transform: translateX(-50%)
  }
}
</style>

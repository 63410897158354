import local from '../messages/en-US.json'

export const DAYJS_LOCALE = 'en'

export default {
  messages: {
    // Local messages *must* come later so potential conflicts uses the project-specific translations
    ...local,
  },
}

import { createApp } from 'vue'
import '@/assets/scss/main.scss'

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle, faCircleNotch, faExclamationCircle, faSearch } from '@fortawesome/free-solid-svg-icons'

import App from './App.vue'
import router from './router.js'
import store from './store/index.js'
import { i18n } from './i18n/i18n.js'

import sentry from '@/utils/sentry.js'

const app = createApp(App)
sentry.init(app, router)

library.add(faCircle, faCircleNotch, faExclamationCircle, faSearch)
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('FontAwesomeLayers', FontAwesomeLayers)

app.use(i18n)
app.use(router)
app.use(store)

app.mount('#app')

import axios from 'axios'

// eslint-disable-next-line import/no-default-export
export default {
  async getRating(token, score) {
    return await axios.get(`/api/rate-carrier-experience`, {
      params: {
        token,
        score,
      },
    })
  },
}

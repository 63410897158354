<template>
  <div class="tracking-home__form">
    <div class="form__panel ui-padding-20">
      <form>
        <div class="">
          <label class="ui-label">
            {{ $t('Tracking code') }}
          </label>
          <input
            v-model.trim="v$.trackingCode.$model"
            :class="formFieldHasError(v$.trackingCode)"
            class="ui-input ui-input--full-width"
            name="trackingCode"
            placeholder="JXC23131231323"
            required
            type="text"
          >
          <FormError v-if="v$.trackingCode.required.$invalid && v$.trackingCode.$error">
            {{ $t('Please fill in this field.') }}
          </FormError>
          <FormError v-if="v$.trackingCode.alphaNumSpace.$invalid && v$.trackingCode.$error">
            {{ $t('Please only use letters, numbers, spaces, dashes (-) and underscores (_).') }}
          </FormError>
        </div>
        <div class="ui-margin-16--top">
          <label class="ui-label">
            {{ $t('Postal code or house number') }}
          </label>
          <input
            v-model.trim="v$.verification.$model"
            :class="formFieldHasError(v$.verification)"
            class="ui-input ui-input--full-width"
            name="verification"
            placeholder="5642 CV"
            required
            type="text"
          >
          <span class="ui-margin-8--top tracking-home__input-hint">
            {{ $t("Only fill in your house number if your country doesn't have postal codes.") }}
          </span>
          <FormError v-if="v$.verification.required.$invalid && v$.verification.$error">
            {{ $t('Please fill in this field.') }}
          </FormError>
          <FormError v-if="v$.verification.alphaNumSpace.$invalid && v$.verification.$error">
            {{ $t('Please only use letters, numbers, spaces, dashes (-) and underscores (_).') }}
          </FormError>
        </div>

        <FormError v-if="error">
          {{ errorMessage }}
        </FormError>

        <div class="ui-margin-16--top tracking-home__save">
          <button
            type="button"
            class="ui-button ui-button--primary tracking-home__save-button"
            data-test="track-button"
            @click="track"
          >
            {{ $t('Track shipment') }}
            <font-awesome-icon icon="search" />
          </button>
        </div>
      </form>
      <Footer class="ui-margin-16--top" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

import FormError from '@/components/FormError.vue'
import Footer from '@/components/Footer.vue'

const alphaNumSpace = helpers.regex(/^[-\w\s]+$/)

export default {
  name: 'AppHome',

  components: {
    FormError,
    Footer,
  },

  data() {
    return {
      trackingCode: '',
      verification: '',
      v$: useVuelidate(),
    }
  },

  validations() {
    return {
      trackingCode: {
        required,
        alphaNumSpace,
      },
      verification: {
        required,
        alphaNumSpace,
      },
    }
  },

  computed: {
    ...mapGetters(['error']),

    errorMessage() {
      if (this.error) {
        if (this.error.response) {
          if (this.error.response.status === 500) {
            return this.$i18n.t('Internal server error')
          } else if (this.error.response.status === 404) {
            return this.$i18n.t('Not found')
          }
          return this.error.response.data.message
        }
        return this.$i18n.t('An unexpected error occurred. Please try again in a few minutes.')
      }
      return null
    },
  },

  methods: {
    formFieldHasError(fieldName) {
      if (fieldName.$error && fieldName.$dirty) {
        return 'form__field--error'
      }
    },

    track() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      if (this.trackingCode && this.verification) {
        this.verification = this.verification.toUpperCase().replace(/\s/g, '')
        this.$router.push({
          name: 'tracking',
          params: {
            trackingCode: this.trackingCode,
            verification: this.verification,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tracking-home__save {
  justify-content: center;
  align-content: center;
  display: flex;
}

.tracking-home__save-button {
    justify-items: center;
    align-items: center;
    display: flex;
    gap: 10px;
}

.tracking-home__form {
  display: flex;
  justify-content: center;
}

.form__panel {
  max-width: 330px;
  width: 90%;
  background-color: $sc-white;
  border: 1px solid rgba($sc-legible-grey, 0.2);
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
}

.tracking-home__input-hint {
  display: block
}
</style>

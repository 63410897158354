import { createI18n } from 'vue-i18n'

import defaultBundle, { DAYJS_LOCALE } from '@/lang/bundles/en-US.lang.js'
import { AVAILABLE_LANGUAGES, DEFAULT_LOCALE } from './constants.js'

/**
 * Sometimes different libraries might use different locale codes and metadata. We keep track
 * of these discrepancies and use the application's locale code as single source of truth.
 */
const loadedBundles = {
  [DEFAULT_LOCALE]: {
    dayjsLocale: DAYJS_LOCALE,
  },
}

export const i18n = createI18n({
  allowComposition: true,
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  formatFallbackMessages: true,
  messages: {
    [DEFAULT_LOCALE]: defaultBundle.messages,
  },
})

export async function loadLocaleAsync(locale) {
  locale = AVAILABLE_LANGUAGES[locale] !== undefined ? locale : DEFAULT_LOCALE

  if (i18n.global.locale !== locale) {
    if (loadedBundles[locale] !== undefined) {
      i18n.global.locale = locale
    } else {
      // Use a relative path here, since otherwise Jests modulemapper does not know where to look
      // for the language files.
      const bundle = await import(/* webpackChunkName: "[request]" */ `../lang/bundles/${locale}.lang.js`)
      i18n.global.setLocaleMessage(locale, bundle.default.messages)
      i18n.global.locale = locale
      loadedBundles[locale] = {
        dayjsLocale: bundle.DAYJS_LOCALE,
      }
    }
  }
  return locale
}

export default i18n.global

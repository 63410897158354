import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

const { VUE_APP_URL, VUE_APP_SENTRY_ENABLED, VUE_APP_SENTRY_DSN, NODE_ENV } = process.env

const isEnabled = VUE_APP_SENTRY_ENABLED === 'true'

const init = (Vue, router) => {
  if (!isEnabled) return

  Sentry.init({
    Vue,
    dsn: VUE_APP_SENTRY_DSN,
    logErrors: true,
    environment: NODE_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [VUE_APP_URL, /^\//],
      }),
    ],
  })
}

export default { init }

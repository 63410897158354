<template>
  <div class="rate-carrier-error_container">
    <font-awesome-icon
      icon="exclamation-circle"
      style="color: #b50000"
    />
    <p class="rate-carrier-error_title">
      {{ $t('tracking.rate_my_carrier.error') }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.rate-carrier-error_container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: $sc-white;
  width: fit-content;
  padding: $sc-spacing-20;
  border: 1px solid $sc-light-grey;
  border-radius: $sc-border-radius;
  box-shadow: 0 4px 3px 2px rgba(0, 0, 0, 0.2);
}

.rate-carrier-error_title {
  font-weight: 600;
  color: $sc-black;
  margin: 0;
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import Tracking from './views/Tracking.vue'
import RateCarrierExperience from './views/RateCarrierExperience.vue'
import RateCarrierExperienceSuccess from './components/RateCarrierExperienceSuccess.vue'
import RateCarrierExperienceError from './components/RateCarrierExperienceError.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/track/:trackingCode/:verification',
    name: 'tracking',
    component: Tracking,
    props: true,
  },
  {
    path: '/rate-carrier-experience',
    name: 'rate-carrier-experience',
    component: RateCarrierExperience,
    children: [
      {
        path: 'success',
        component: RateCarrierExperienceSuccess,
      },
      {
        path: 'error',
        component: RateCarrierExperienceError,
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router

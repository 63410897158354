import { LOCALE, DEFAULT_LOCALE } from './constants.js'

/**
 * Given a locale identifier, try to normalize it to one of the locales known by
 * the application.
 *
 * SC-21168: get a normalized version of a language identifier. Some users have
 * different configurations that doesn't always match the supported languages
 * of the application.
 *
 * Example:
 *
 * ```javascript
 * normalizeLocale(window.navigator.language)
 * ```
 *
 * @param {string} locale
 * @return {string}
 */
export function normalizeLocale(locale) {
  const allLocales = Object.values(LOCALE)

  if (allLocales.includes(locale)) {
    return locale
  }

  let [prefix, suffix] = locale.split('-')

  if (prefix === undefined) {
    return DEFAULT_LOCALE
  }
  prefix = prefix.slice(0, 2).toLowerCase()

  if (suffix !== undefined) {
    suffix = suffix.slice(0, 2).toUpperCase() // Safari reports language codes in lowercase (i.e en-gb)
  }

  const knownSuffixes = allLocales.map((code) => code.slice(-2))

  if (knownSuffixes.includes(suffix) === false) {
    const defaultPrefix = DEFAULT_LOCALE.slice(0, 2)
    if (suffix === undefined && prefix === defaultPrefix) {
      return DEFAULT_LOCALE
    }
    return allLocales.find((code) => code.startsWith(prefix)) || DEFAULT_LOCALE
  }

  return `${prefix}-${suffix}`
}

<template>
  <footer
    class="logo-footer ui-margin-top-16"
    data-test="preview-logo-footer"
  >
    <UiPromoFooter>
      {{ $t('This tracking page is powered by') }}
    </UiPromoFooter>
  </footer>
</template>

<script>
import { UiPromoFooter } from '@sendcloud/ui-library'

export default {
  name: 'AppFooter',

  components: {
    UiPromoFooter,
  },
}
</script>

<style lang="scss" scoped>
.ui-logo-footer {
  gap: 8px;
}
</style>

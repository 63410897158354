export default {
  "An unexpected error occurred. Please try again in a few minutes.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error occurred. Please try again in a few minutes."])},
  "Enter your tracking code and postal code and find your shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your tracking code and postal code and find your shipment"])},
  "Internal server error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal server error"])},
  "Only fill in your house number if your country doesn't have postal codes.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only fill in your house number if your country doesn't have postal codes."])},
  "Please fill in this field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in this field."])},
  "Please only use letters, numbers, spaces, dashes (-) and underscores (_).": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please only use letters, numbers, spaces, dashes (-) and underscores (_)."])},
  "Postal code or house number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code or house number"])},
  "The page might have been moved or removed.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page might have been moved or removed."])},
  "This tracking page is powered by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This tracking page is powered by"])},
  "Track shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track shipment"])},
  "Track your shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track your shipment"])},
  "Tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking"])},
  "Tracking code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking code"])},
  "tracking.confirmation.body_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have received your input, we will use it to improve our services."])},
  "tracking.confirmation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for rating your experience for this order."])},
  "tracking.rate_my_carrier.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, something went wrong. Please try again later."])},
  "We are fetching your parcel.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are fetching your parcel."])},
  "We could not find this page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not find this page"])}
}
import { createStore } from 'vuex'

import parcelService from '../api/parcel.js'

const state = {
  parcel: null,
  error: null,
}

const getters = {
  parcel: (state) => state.parcel,
  error: (state) => state.error,
  locale: (state) => state.locale,
}

const mutations = {
  setParcel: (state, parcel) => (state.parcel = parcel),
  setError: (state, error) => (state.error = error),
}

const actions = {
  async loadParcel({ commit }, { trackingCode, verification }) {
    let parcel = null
    let error = null
    try {
      parcel = await parcelService.getParcel(trackingCode, verification)
      await commit('setParcel', parcel)
    } catch (e) {
      error = e
      await commit('setError', error)
    }
  },
}

const storeConfig = {
  actions,
  getters,
  mutations,
  state,
}

export default createStore(storeConfig)

// Separate export for use in unit tests
export { storeConfig }

export const LOCALE = {
  GERMAN_DE: 'de-DE',
  ENGLISH_UK: 'en-GB',
  ENGLISH_US: 'en-US',
  SPANISH_ES: 'es-ES',
  FRENCH_FR: 'fr-FR',
  ITALIAN_IT: 'it-IT',
  DUTCH_NL: 'nl-NL',
}

export const DEFAULT_LOCALE = process.env.VUE_APP_I18N_LOCALE || LOCALE.ENGLISH_US

/** @type {Object.<string, string>} */
export const AVAILABLE_LANGUAGES = {
  [LOCALE.GERMAN_DE]: 'Deutsch',
  [LOCALE.ENGLISH_UK]: 'English (UK)',
  [LOCALE.ENGLISH_US]: 'English (US)',
  [LOCALE.SPANISH_ES]: 'Español',
  [LOCALE.FRENCH_FR]: 'Français',
  [LOCALE.ITALIAN_IT]: 'Italiano',
  [LOCALE.DUTCH_NL]: 'Nederlands',
}

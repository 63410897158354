<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { loadLocaleAsync } from './i18n/i18n.js'
import { AVAILABLE_LANGUAGES, DEFAULT_LOCALE } from './i18n/constants.js'
import { normalizeLocale } from './i18n/utils.js'

function getURLParam(variable) {
  try {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get(variable)
  } catch (e) {
    // IE11 does not support URLSearchParams
    const query = window.location.search.substring(1)
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      if (pair[0] === variable) {
        // Postal code might contain a plus sign, i.e. an encoded space
        return decodeURIComponent(pair[1].replace(/\+/g, '%20'))
      }
    }
    return ''
  }
}

export default {
  mounted() {
    this.detectUserLanguage()
  },
  methods: {
    async detectUserLanguage() {
      const browserLanguage = navigator.language
      const languageOverride = getURLParam('language')
      const userLanguage = normalizeLocale(languageOverride || browserLanguage || DEFAULT_LOCALE)
      
      if (userLanguage !== DEFAULT_LOCALE && AVAILABLE_LANGUAGES[userLanguage] !== undefined) {
        try {
          await loadLocaleAsync(userLanguage)
        } catch (err) {
          return // Avoid touching the documentElement.lang attribute in this case
        }
      }
      document.documentElement.setAttribute('lang', userLanguage)
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  color: $sc-dark-grey;
  height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 16px;
}
</style>

<template>
  <span class="form-error">
    <font-awesome-icon
      icon="exclamation-circle"
      class="form-error__icon"
    />
    <slot />
  </span>
</template>

<script>
export default {
  name: 'FormError',
}
</script>

<style>
.form-error {
  color: #dc3545;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin-top: 4px;
}

.form-error__icon {
  flex-shrink: 0;
  margin-top: 2px;
  margin-right: 4px;
  width: 16px;
  height:16px;
}
</style>

<template>
  <section class="tracking-section">
    <div class="tracking-loader">
      <div class="tracking-loader__panel">
        <span class="tracking-loader__text">
          {{ $t("We are fetching your parcel.") }}
        </span>
        <font-awesome-icon
          icon="circle-notch"
          spin
          size="3x"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TrackingPage',
  props: {
    trackingCode: {
      required: true,
      type: String,
    },
    verification: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapGetters(['parcel', 'error', 'theme']),
  },
  watch: {
    parcel() {
      if (this.parcel) {
        if (this.parcel.redirect_to) {
          window.location = this.parcel.redirect_to
        } else {
          this.$router.push({ name: 'home' })
        }
      }
    },
    error() {
      if (this.error) {
        this.$router.push({ name: 'home' })
      }
    },    
  },
  created() {
    this.$store.dispatch('loadParcel', {
      trackingCode: this.trackingCode,
      verification: this.verification,
    })
  },
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 950px;
}

.tracking-loader {
  margin: 0 auto;
  max-width: 790px;
  padding: 0 1rem;
}

.tracking-loader__panel {
  align-items: center;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;

  @media screen and (min-width: 500px) {
    padding: 2rem;
  }
}

.tracking-loader__text {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  text-align: center;

  @media screen and (min-width: 500px) {
    font-size: 2rem;
  }
}
</style>
